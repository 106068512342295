<template>
  <v-main class="padding100">
    <navbar/>
    <side-bar-admin/>
    <!-- Barre de Navigation -->
    <v-container fluid fill-height>
      <v-row align-center justify-center>
        <v-col xs12 sm8 md4> 
          <h1>Liste des utilisateurs</h1>
          <v-data-table v-if="show == 1" :headers="headers" :items="users" :items-per-page="10" class="elevation-1">
            <template v-slot:item.date_creation="{ item }">
              {{ formatDate(item.date_creation) }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn class="btn-delete" @click="email = item.email; dialog2 = true; ">supprimer</v-btn>
            </template>
            <template v-slot:item.tag_attributed="{ item }">
              <v-select
              v-if="item.tag_attributed && item.tag_attributed.length"
              :items="item.tag_attributed"
              v-model="tag_selected"
              label="Tags"
              chips
              dense
              @change="email = item.email; dialog1 = true"
              ></v-select>
            </template>
            <template v-slot:item.matrice_attributed="{ item }">
              <v-select
              v-if="item.matrice_attributed && item.matrice_attributed.length"
              :items="item.matrice_attributed"
              v-model="tag_selected"
              label="Matrice"
              chips
              dense
              @change="email = item.email; dialog1 = true"
              ></v-select>
            </template>
            <template v-slot:item.capteur_vibration="{ item }">
              <v-select
              v-if="item.capteur_vibration && item.capteur_vibration.length"
              :items="item.capteur_vibration"
              v-model="tag_selected"
              label="Vibration"
              chips
              dense
              @change="email = item.email; dialog1 = true"
              ></v-select>
            </template>
            <template v-slot:item.capteur_sphere="{ item }">
              <v-select
              v-if="item.capteur_sphere && item.capteur_sphere.length"
              :items="item.capteur_sphere"
              v-model="tag_selected"
              label="Sphere"
              chips
              dense
              @change="email = item.email; dialog1 = true"
              ></v-select>
            </template>
            <template v-slot:item.capteur_environnement="{ item }">
              <v-select
              v-if="item.capteur_environnement && item.capteur_environnement.length"
              :items="item.capteur_environnement"
              v-model="tag_selected"
              label="Environnement"
              chips
              dense
              @change="email = item.email; dialog1 = true"
              ></v-select>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog1" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmation</v-card-title>
        <v-card-text>
          Êtes vous sûr de vouloir déposséder l'utilisateur: {{ email }} du tag {{ tag_selected}} ?
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="dialog1 = false">Cancel</v-btn>
          <v-btn color="success" text @click="removeAttribution">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="290">
			<v-card>
				<v-card-title class="headline">Confirmation</v-card-title>
				<v-card-text>
					Êtes-vous sûr de vouloir supprimer l'utilisateur {{ email}} ? Son compte sera détruit et perdra tout son matériel attribué.
				</v-card-text>
				<v-card-actions>
					<v-btn color="blue darken-1" text @click="dialog2 = false">Non</v-btn>
					<v-btn color="blue darken-1" text @click="deleteUser">Oui</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    <br><br>
   
  </v-main>
</template>

<script>
import { switchToken } from '../../functions/test-token';
import moment from 'moment';
moment.locale('fr');
import navbar from '@/components/NavBarCloud.vue';
import SideBarAdmin from '../../components/SideBarAdmin.vue';
export default {
  components: { SideBarAdmin, navbar },
  data() {
    return {
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      username: '',
      show: 0,
      headers: [
        { text: 'Utilisateur', value: 'email' },
        { text: 'Supprimer', value: 'action' },
        { text: 'date de création', value: 'date_creation'},
        { text: 'Nom', value: 'nom' },
        { text: 'société', value: 'societe' },
        { text: 'implants', value: 'tag_attributed' },
        { text: 'matrices', value: 'matrice_attributed' },
        { text: 'capteurs vibration ', value: 'capteur_vibration' },
        { text: 'capteur spheres ', value: 'capteur_sphere' },
        { text: 'capteurs environnement', value: 'capteur_environnement' },
        { text: 'admin', value: 'admin' },
      ],
      email: '',
      matrice_selected: '',
      tag_selected: '',
      vibration_selected: '',
      sphere_selected: '',
      environnement_selected: '',

    };
  },

  mounted() {
    this.GetUsers();
  },

  methods: {
    async GetUsers() {
      const url = this.$api.getRESTApiUri() + `/get-users`;
				const accessToken = localStorage.getItem('accessToken');
				const headers = new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${accessToken}`
				});
				return fetch(url, { headers: headers })
					.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
						// Refaites la requête avec le nouveau token
						this.GetUsers();
						});
					}
					return res.text();
				})
        .then((result) => {
          this.users = JSON.parse(result);
          this.show = 1;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async removeAttribution(){
			const url = this.$api.getRESTApiUri() + `/admin/supress-tag-attribution`;
			const accessToken = localStorage.getItem('accessToken');
				return fetch(url, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
					},
					body: JSON.stringify({
						tag_id: this.tag_selected,
						email: this.email
					}),
				})
        .then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
							// Refaites la requête avec le nouveau token
							this.removeAttribution();
						});
					}
					return res.text();
				})
        .then(async (result) => {
					const data = JSON.parse(result);
					console.log(data)
          const user = this.users.find((user) => user.email === this.email);
          user.tag_attributed = user.tag_attributed.filter((tag) => tag !== this.tag_selected);
          this.dialog1 = false ;
				})
				.catch(error => {
					console.log(error)
				});
    },
    
    async deleteUser() {
      const accessToken = localStorage.getItem('accessToken');
      const url = this.$api.getRESTApiUri() + `/admin/supress-user/`;
      return fetch(url, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
					},
					body: JSON.stringify({
						email: this.email
					}),
				})
        .then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
							// Refaites la requête avec le nouveau token
							this.deleteUser();
						});
					}
					return res.text();
				})
        .then((response) => {
          if (response.ok) {
            console.log('User deleted successfully');
            // Perform any additional desired logic
          } else {
            console.error('Error:', response.status);
            // Handle the error if needed
          }
          this.dialog2 = false;
        })
        .catch((error) => {
          console.error('Error:', error);
          // Handle the error if needed
        });
        
    },
    formatDate(date) {
        return moment(date).format('LL, LT');
    },
  },
};
</script>

<style scoped>
.btn-delete{
  background-color: indianred !important;
  color: aliceblue;
  padding: 5px !important;
  border-radius: 10%;
  font-size: 15px;
}
</style>
